import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import './ArticlePage.css';
import Header from '../components/Header';
import Menu from '../components/Menu';
import ImageGallery from 'react-image-gallery';
import { FaExclamationCircle, FaShareAlt, FaPlus, FaMinus } from "react-icons/fa";
import "react-image-gallery/styles/css/image-gallery.css";

// Utility function to get the value of a cookie
const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const ArticlePage = () => {
    const { id } = useParams(); // Get id from params
    const [article, setArticle] = useState(null);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayContent, setDisplayContent] = useState(false);
    const [fontSize, setFontSize] = useState(16);
    const [menuOpen, setMenuOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const galleryRef = useRef(null);

    const [selectedLang, setSelectedLang] = useState(() => {
        return localStorage.getItem('selectedLang') || 'ar';
    });

    // Debugging statement to check the value of lang
    console.log("Current language:", selectedLang);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const updateMetaTags = (title, description) => {
        document.title = title;
        let metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        } else {
            metaDescription = document.createElement('meta');
            metaDescription.name = "description";
            metaDescription.content = description;
            document.head.appendChild(metaDescription);
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: article.title,
                url: window.location.href
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            alert('Your browser does not support the Web Share API');
        }
    };

    const handleIncreaseFontSize = () => {
        setFontSize(prevFontSize => prevFontSize + 2);
        localStorage.setItem('fontSize', fontSize + 2);
    };

    const handleDecreaseFontSize = () => {
        setFontSize(prevFontSize => prevFontSize - 2);
        localStorage.setItem('fontSize', fontSize - 2);
    };

    useEffect(() => {
        const savedFontSize = localStorage.getItem('fontSize');
        if (savedFontSize) {
            setFontSize(parseInt(savedFontSize, 10));
        }
    }, []);

    const fetchArticle = async (selectedLang) => {
        console.log("lang passed: " + selectedLang);
        setLoading(true);
        try {
            const response = await axios.get(`https://www.bayt.org.il/api/index.php?action=apparticle&lang=${selectedLang}&identifier=${id}`);
            if (response.data && response.data.data && response.data.data.article) {
                const articleData = response.data.data.article;
                const categoriesData = response.data.data.categories;
                articleData.fData = articleData.fData ? articleData.fData.replace(/<p>\s*<\/p>/g, '') : '';

                // Extract images from fData and check the number of images
                const parser = new DOMParser();
                const doc = parser.parseFromString(articleData.fData, 'text/html');
                const images = Array.from(doc.querySelectorAll('img'));

                if (images.length > 1) {
                    const galleryImagesArray = images.map(img => ({
                        original: img.src.replace('http://comark-light.localhost/', 'https://www.bayt.org.il/') + '?width=1800',
                        thumbnail: img.src.replace('http://comark-light.localhost/', 'https://www.bayt.org.il/') + '?aspect_ratio=1:1',
                        
                    }));

                    setGalleryImages(galleryImagesArray);

                    // Remove images from the article content if there are multiple images
                    images.forEach(img => img.remove());
                    articleData.fData = doc.body.innerHTML;
                }

                setArticle(articleData);
                setCategories(categoriesData);
                updateMetaTags(articleData.title, articleData.sData);
                window.scrollTo(0, 0);

                setTimeout(() => setDisplayContent(true), 250);
            } else {
                console.error('Article data not found in response:', response.data);
            }
        } catch (error) {
            console.error('Error fetching article data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchArticle(selectedLang);
    }, [id, selectedLang]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };

        switch (selectedLang) {
            case 'ar':
                return new Intl.DateTimeFormat('ar-EG', options).format(date);
            case 'he':
                return new Intl.DateTimeFormat('he-IL', options).format(date);
            default:
                return new Intl.DateTimeFormat('ar-EG', options).format(date);
        }
    };

    const handleImageClick = () => {
        if (galleryRef.current) {
            galleryRef.current.fullScreen();
        }
    };

    // Translation function for the loading text
    const translateLoadingText = () => {
        switch (selectedLang) {
            case 'ar':
                return 'جار التحميل...';
            case 'he':
                return 'טוען...';
            default:
                return 'Loading...';
        }
    };

    // Translation function for gallery title
    const translateGalleryTitle = () => {
        switch (selectedLang) {
            case 'ar':
                return 'معرض الصور';
            case 'he':
                return 'גלריית תמונות';
            default:
                return 'Photo Gallery';
        }
    };

    if (loading || !displayContent) {
        return (
            <div className="loading-screen">
                <div className="loading-content">
                    <img src="/images/logo-white.png" alt="Logo" className="loading-logo" />
                    <p className="loading-text">{translateLoadingText()}</p>
                </div>
            </div>
        );
    }

    if (!article) {
        return <div>No article found</div>;
    }

    const cleanHtml = article ? DOMPurify.sanitize(article.fData) : '';

    return (
        <>
            <Header 
                toggleMenu={toggleMenu}
                handleShare={handleShare}
                handleIncreaseFontSize={handleIncreaseFontSize}
                handleDecreaseFontSize={handleDecreaseFontSize}
            />
            <Menu 
                isOpen={menuOpen} 
                toggleMenu={toggleMenu} 
                categories={categories} // Pass categories to Menu
                selectedLang={selectedLang} // Pass selectedLang to Menu
            />
            <div className="article-container">
                <div className="article-header">
                    <div className="article-controls">
                        <button onClick={handleDecreaseFontSize} className="font-control"><FaMinus /></button>
                        <button onClick={handleIncreaseFontSize} className="font-control"><FaPlus /></button>
                        <button onClick={handleShare} className="share-button"><FaShareAlt /></button>
                    </div>
                    <h1 className="article-title">{article.title}</h1>
                </div>
          
                <div 
                    className="article-content" 
                    style={{ fontSize: `${fontSize}px` }}
                    dangerouslySetInnerHTML={{ __html: cleanHtml }} 
                />
             
                {galleryImages.length > 1 && (
                    <div className="article-gallery">
                        <h2 className="gallery-title">{translateGalleryTitle()}</h2>
                        <ImageGallery
                            ref={galleryRef}
                            items={galleryImages}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            onClick={handleImageClick} 
                        />
                    </div>
                )}
             
            </div>
        </>
    );
};

export default ArticlePage;
