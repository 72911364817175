import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css'; // Import the CSS file
import { CgMenu } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa6";

const Header = ({ toggleMenu, transparent }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isArticlePage = location.pathname.startsWith('/article');
  const isVideoPage = location.pathname.startsWith('/video');

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <header className={`header ${transparent ? 'transparent' : ''}`}>
      <div className="header-container">
        {(isArticlePage || isVideoPage) && (
          <div className="back-icon" onClick={handleBack}>
            <FaArrowRight /> {/* Right arrow symbol for back */}
          </div>
        )}
      
        <img 
          src="/images/logo.png" 
          alt="Logo" 
          className="logo" 
        />
        
        <div className="menu-icon" onClick={toggleMenu}>
          <CgMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
