import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';
import SectionPage from './pages/SectionPage';
import ArticlePage from './pages/ArticlePage';
import './App.css'; // Import the CSS file

// Import font faces globally
import './fonts.css'; // Centralize font declarations

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(() => {
    return localStorage.getItem('selectedLang') || 'ar';
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem('selectedLang', lang);
  };

  useEffect(() => {
    // Apply the appropriate language class to the body
    document.body.setAttribute('lang', selectedLang);
  }, [selectedLang]);

  return (
    <Router>
      <div className={`app-container ${menuOpen ? 'menu-open' : ''}`}>
        <div className="content">
          <Routes>
            <Route exact path="/" element={<MainPage selectedLang={selectedLang} onLanguageChange={handleLanguageChange} />} />
            <Route path="/section/:identifier" element={<SectionPage selectedLang={selectedLang} onLanguageChange={handleLanguageChange} />} />
            <Route path="/article/:id" element={<ArticlePage selectedLang={selectedLang} />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
