import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <div className="comark-signature">
      <a href="https://www.facebook.com/profile.php?id=61556411136273" target="_blank" rel="noopener noreferrer">
        <img
          src="https://comarkit.com/delivery/icon_43x20.png"
          alt="לוגו COMARK"
          className="comark-logo"
        />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=61556411136273"
        target="_blank"
        rel="noopener noreferrer"
        className="comark-text"
      >
        Comark, <span>coding and marketing</span>
      </a>
     
    </div>
  );
};

export default Footer;
