import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menu.css'; // Import the CSS file

const Menu = ({ isOpen, toggleMenu, categories = [], selectedLang, onLanguageChange }) => {
  const location = useLocation();
  const isArticlePage = location.pathname.startsWith('/article'); // Check if the current page is an article page

  useEffect(() => {
    if (window.innerWidth >= 768) {
      toggleMenu(true); // Set menu open by default on desktop devices
    }
  }, []);

  const isDesktop = window.innerWidth >= 768;

  // Function to translate the "Home Page" text based on selected language
  const translateHomePageText = () => {
    switch (selectedLang) {
      case 'ar':
        return 'صفحة البداية'; // Arabic
      case 'he':
        return 'דף הבית'; // Hebrew
      default:
        return 'Home Page'; // Default fallback
    }
  };

  return (
    <div className={`menu ${isOpen || isDesktop ? 'open' : ''} ${isDesktop ? 'desktop' : 'mobile'}`}>
      {!isDesktop && (
        <div className="close-icon" onClick={toggleMenu}>&times;</div> /* Keep the close icon for mobile */
      )}
      <ul>
        <li><Link to="/" onClick={toggleMenu}>{translateHomePageText()}</Link></li>
        {categories.map((category) => (
          <li key={category.ID}>
            <Link 
              to={category.articleid ? `/article/${category.articleid}` : `/section/${category.ID}`} 
              onClick={toggleMenu}
            >
              {category.Name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="language-switcher">
        {isArticlePage ? (
          selectedLang === 'ar' ? (
            <Link 
              to={`/?lang=he`} 
              className="language-link"
              onClick={toggleMenu}
            >
              עברית
            </Link>
          ) : (
            <Link 
              to={`/?lang=ar`} 
              className="language-link"
              onClick={toggleMenu}
            >
              عربي
            </Link>
          )
        ) : (
          <>
            <button 
              className={selectedLang === 'ar' ? 'active' : ''} 
              onClick={() => onLanguageChange('ar')}
            >
              عربي
            </button>
            <button 
              className={selectedLang === 'he' ? 'active' : ''} 
              onClick={() => onLanguageChange('he')}
            >
              עברית
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
