import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Header from '../components/Header';
import Menu from '../components/Menu';
import { CgPlayButtonR } from 'react-icons/cg';
import './MainPage.css';

const MainPage = () => {
  const location = useLocation();

  // Retrieve language from URL or localStorage
  const getInitialLang = () => {
    const params = new URLSearchParams(location.search);
    const langFromUrl = params.get('lang');
    if (langFromUrl) {
      document.cookie = `selectedLang=${langFromUrl}; path=/`;
      localStorage.setItem('selectedLang', langFromUrl);
      return langFromUrl;
    }
    return localStorage.getItem('selectedLang') || 'ar';
  };

  const [selectedLang, setSelectedLang] = useState(getInitialLang);
  const [mainNews, setMainNews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [aboutUs, setAboutUs] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fetchData = async (lang) => {
    try {
      const response = await axios.get(`https://www.bayt.org.il/api/index.php?action=apphome&lang=${lang}`);
      setMainNews(response.data.data.articles);
      setCategories(response.data.data.categories);

      // Clean the aboutUs content by removing images
      const cleanedAboutUs = response.data.data.aboutus.replace(/<img[^>]*>/g, '');
      setAboutUs(cleanedAboutUs);

      preloadImages(response.data.data.articles);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedLang);
  }, [selectedLang]);

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem('selectedLang', lang);
    document.cookie = `selectedLang=${lang}; path=/`;
    fetchData(lang);

    // Apply the appropriate language class to the body
    document.body.setAttribute('lang', lang);
  };

  const preloadImages = (articles) => {
    const imagePromises = articles.map(article => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = `${article.picture}?aspect_ratio=140:100&width=140`;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
        setTimeout(() => {
          setLoading(false);
          setShowContent(true); 
        }, 250);
      })
      .catch(() => {
        setImagesLoaded(true);
        setTimeout(() => {
          setLoading(false);
          setShowContent(true); 
        }, 250);
      });
  };

  const getBannerImage = () => {
    return selectedLang === 'ar' ? 'images/banner-ar.png' : 'images/banner-he.png';
  };

  const translateLoadingText = () => {
    switch (selectedLang) {
      case 'ar':
        return 'جار التحميل...'; // Arabic: "Loading..."
      case 'he':
        return 'טוען...'; // Hebrew: "Loading..."
      default:
        return 'Loading...';
    }
  };

  const translateSeeMore = () => {
    switch (selectedLang) {
      case 'ar':
        return 'المزيد';
      case 'he':
        return 'ראה עוד';
      default:
        return 'See More';
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
  };

  if (loading || !showContent) {
    return (
      <div className="loading-screen">
        <div className="loading-content">
          <img src="/images/logo-white.png" alt="Logo" className="loading-logo" />
          <p className="loading-text">{translateLoadingText()}</p>
        </div>
      </div>
    );
  }

  const renderMainNews = () => {
    return (
      <Carousel
        responsive={responsive}
        rtl={true}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 0.5s ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        arrows={false}
        additionalTransfrom={0}
        rewind={false}
      >
        {mainNews.map((news, index) => (
          <div key={news.ID}>
            <Link to={`/article/${news.ID}`}>
              <div className="imgwrap">
                <img src={`${news.picture}`} alt={news.title} className="news-image" />
                {news.mobilevideo && <CgPlayButtonR className="video-icon" />}
              </div>
              <div className="wrapper">
                <h3 className="news-title">{news.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <Menu
        isOpen={menuOpen}
        toggleMenu={toggleMenu}
        categories={categories}
        selectedLang={selectedLang}
        onLanguageChange={handleLanguageChange}
      />
      <div className="banner-section">
        <div className="banner-container">
          <img src={getBannerImage()} alt="Banner" className="banner-image" />
        </div>
      </div>
      
      {/* About Us Section */}
      <div className="about-us-section">
        <div className="container content">
          <div className="about-us-content" dangerouslySetInnerHTML={{ __html: aboutUs }} />
        </div>
      </div>

      <div className="slick-section">
        <div className="news-container">
          <div className="section" data-title="mainNews">
            {renderMainNews()}
          </div>
        </div>
      </div>
 
    </>
  );
};

export default MainPage;
