import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import './SectionPage.css';

const SectionPage = () => {
  const { identifier } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(() => {
    return localStorage.getItem('selectedLang') || 'ar';
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const fetchCategoryData = async (lang) => {
    try {
      const response = await axios.get(`https://www.bayt.org.il/api/index.php?action=appcategory&identifier=${identifier}&lang=${lang}`);
      setCategoryData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching category data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoryData(selectedLang);
  }, [selectedLang, identifier]);

  useEffect(() => {
    if (categoryData) {
      document.title = categoryData.title;
    }
  }, [categoryData]);

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem('selectedLang', lang);
    fetchCategoryData(lang);
  };

  const translateLoadingText = () => {
    switch (selectedLang) {
      case 'ar':
        return 'جار التحميل...'; // Arabic: "Loading..."
      case 'he':
        return 'טוען...'; // Hebrew: "Loading..."
      default:
        return 'Loading...';
    }
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-content">
          <img src="/images/logo-white.png" alt="Logo" className="loading-logo" />
          <p className="loading-text">{translateLoadingText()}</p>
        </div>
      </div>
    );
  }

  if (!categoryData) {
    return <div>No data found for this category</div>;
  }

  const { title, articles, categories } = categoryData;

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      <div className="category-section">
        <Menu
          isOpen={menuOpen}
          toggleMenu={toggleMenu}
          categories={categories}
          selectedLang={selectedLang}
          onLanguageChange={handleLanguageChange}
        />
        <div className="category-container">
          <h1 className="category-title">{title}</h1>
          <div className="articles-list">
            {articles.map((article) => (
              <div key={article.ID} className="article-item">
                <Link to={`/article/${article.ID}`}>
                  <img 
                    src={`https://www.bayt.org.il${article.picture.replace('..', '')}?aspect_ratio=1:1`} 
                    alt={article.title} 
                    className="article-image" 
                  />
                  <h2 className="article-title2">{article.title}</h2>
                  <p className="article-date">{new Intl.DateTimeFormat(selectedLang === 'ar' ? 'ar-EG' : 'he-IL', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true
                  }).format(new Date(article.pdate))}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionPage;
